import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useLocation, navigate } from "@reach/router";
import LocationLayout from '../../components/locationLayout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Search } from 'lucide-react';

const LocationsProduits = ({ data }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const activeCategory = searchParams.get("categorie") || "tous";
    const categories = data.allPrismicLocationcategory.edges.map(({ node }) => ({
        id: node.data.name.text.toLowerCase().replace(/\s+/g, "-"),
        label: node.data.name.text
    }));

    const [searchTerm, setSearchTerm] = useState("");

    const BUTTONS = [
        { id: "tous", label: "Tous les produits" },
        ...categories
    ];

    const handleClick = (id) => {
        navigate(`?categorie=${id}`);
    };

    const filteredProducts = data.allPrismicLocation.edges.filter(({ node }) => {
        const matchesCategory = activeCategory === "tous" ||
            node.data.categories.some(cat =>
                cat.category?.document?.data?.name?.text?.toLowerCase().replace(/\s+/g, "-") === activeCategory
            );
        
        const matchesSearch = node.data.name.text.toLowerCase().includes(searchTerm.toLowerCase());
        
        return matchesCategory && matchesSearch;
    });

    return (
        <LocationLayout>
            <div className="location-home w-[1920px] max-w-[100%] justify-center mx-auto">
                <h1 className="">Produits</h1>

                <div className="w-4/5 mx-auto mt-4 relative">
                  <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="px-4 py-2 border-[2px] border-black rounded-3xl w-full md:w-1/4 pr-10" />
                  {searchTerm === "" && (
                    <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                  )}
                </div>

                {/* BOUTONS DE FILTRAGE */}
                <div className="md:flex block justify-start w-4/5 mx-auto md:space-x-4 space-x-0 mt-8 mb-6">
                    {BUTTONS.map(({ id, label }) => (
                        <button 
                            key={id}
                            onClick={() => handleClick(id)}
                            className={`px-6 py-3 text-lg rounded-lg font-poppins w-full font-black transition-all !my-2
                                ${activeCategory === id 
                                    ? "!bg-white !text-black border-2 border-black" 
                                    : "!bg-black !text-white border-2 hover:!bg-white hover:!text-black"}`
                            } >
                            {label}
                        </button>
                    ))}
                </div>

                {/* LISTE DES PRODUITS */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6 md:mx-24 mx-0">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map(({ node }) => (
                            <div key={node.data.name.text} className="p-4 rounded-lg text-center">
                                <a href={`/location/produit/${node.id}`}>
                                    {node.data.image.gatsbyImageData && (
                                        <GatsbyImage  image={getImage(node.data.image)} 
                                                      alt={node.data.image.alt ?? node.data.name.text} 
                                                      className="md:mt-4 mt-0 mx-auto rounded-lg max-w-[400px] max-h-[200px] hover:scale-105 transition-transform" />
                                    )}
                                    <p className="uppercase text-lg font-bold font-poppins mt-2">{node.data.name.text}</p>
                                </a>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-500 my-6">Aucun produit ne correspond à votre recherche.</p>
                    )}
                </div>
            </div>
        </LocationLayout>
    );
};

export default LocationsProduits;

export const locationResult = graphql`
query {
    allPrismicLocation {
        edges {
          node {
            id
            data {
              image {
                alt
                gatsbyImageData
              }
              name {
                text
              }
              description {
                text
                html
              }
              stock
              categories {
                category {
                  document {
                    ... on PrismicLocationcategory { 
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
    allPrismicLocationcategory {
        edges {
          node {
            data {
              name {
                text
              }
            }
          }
        }
    }
}`